@import "variables";

.btn {
  border-radius: 0.25rem;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  font-weight: normal;
  line-height: normal;
  padding: 0.5rem 1.5rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all 300ms ease-out;
  &-info {
    color: white;
    &:hover, &:active {
      color: white;
    }
  }
  &-primary {
    background-color: $blue;
    border: 2px solid $blue;
    color: #fff;
    &:hover, &:active {
      background-color: $cyan;
      border-color: $cyan;
      color: white;
    }
  }
  &-success {
    background-color: $green;
    border: 2px solid $green;
    color: white;
    color: #fff;
    &:hover, &:active {
      color: white;
    }
  }
  &:active {
    color: white;
  };
  &:disabled {
    background-color: $gray;
    border-color: $gray;
    color: white;
    &:hover {
      background-color: $gray;
    border-color: $gray;
    }
    cursor: not-allowed;
    pointer-events: visibleStroke;
  }
}
.btn-link {
  &:hover {
    text-decoration: underline;
  }
  &:disabled {
    background-color: transparent!important;
    color: $gray;
    border: none;
  }
}

select {
  appearance: none;
  -webkit-appearance: none;
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='m7 10 5 5 5-5' stroke='%2351247a' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'%3e%3c/path%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.25rem center;
  padding-right: 2.5rem;
  cursor: pointer;
  line-height: 1.2;
  width: 100%;
}

input:not([type=checkbox]):not([type=radio]):not([type=submit]), select, textarea {
  background-color: #fff;
  padding: 0.75rem 1rem;
  border: 1px solid #e7e3e0;
  border-radius: 2px;
  font-size: 1rem;
  color: #2b2a29;
  line-height: 1;
}
input:not([type=checkbox]):not([type=radio]):not([type=submit]):focus, select:focus, textarea:focus {
  border-color: #1e72c6;
  box-shadow: 0 0 0 1px #1e72c6 inset;
  outline: none;
}

.table .thead-dark {
  th {
    vertical-align: bottom;
    padding: 0.5rem 1rem;
    color: $primary;
    text-align: left;
    border: 1px solid #d7d1cc;
    font-weight: 500;
    background-color: rgba(215,209,204,.3);
  }
  tr {
    border-top: 1px solid;
  }
}
.table {
  margin: 0;
  padding: 0;
  border-collapse: collapse;
  border-spacing: 0;
  box-sizing: border-box;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #2b2a29;
  list-style: none;
  tbody {
    tr {
      td {
        padding: 0.5rem 1rem ;
        border: 1px solid #d7d1cc;
        text-align: left ;
      }
    }
  }
}

.navbar {
  position: relative;
  padding: 0.5rem 1rem;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

.badge-success {
  color: #fff;
  background-color: $green;
}

a {
  color: $primary;
  text-decoration: none;
  background-color: transparent;

  &:hover {
    text-decoration: underline;
  }
}

.breadcrumb {
  padding: 0.75rem 1rem;
}

.list-group-item {
  padding: 0.75rem 1.25rem;
}

.table > :not(:first-child) {
  border-top: none;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.form-group {
  margin-bottom: 1rem;
}

.factor_sorting {
  cursor: pointer;
  vertical-align: middle;

  div {
    display: inline-block;
    margin-left: 10px;
    width: 10px;
    svg {
      display: none;
    }
    .fa-sort-up {
      top: 5px;
      position: relative;
    }
  }

  &:hover {
    svg {
      display: inline-block;
    }
  }
}

.factor_sorting.sorting_visible {
  svg {
    display: inline-block;
  }
}


.fixScoreCard {
  position: fixed;
  bottom: 10px;
  z-index: 100;
  right: 10px;
  padding: 10px 20px;
  background: #f9fafb;
  color: $gray;
  border-radius: 2px;
  box-shadow: 2px 0 6px 0 rgba(35, 34, 34, 0.5);
}

.rc-slider-mark {
  font-size: 14px!important;
}

.mb-6 {
  margin-bottom: 5rem !important;
}
.rc-slider-dot {
  height: 16px !important;
  width: 16px !important;
}
.rc-slider-mark-text {
  max-width: 96px!important;
  @media (max-width: 600px) {
    font-size: 12px!important;
    max-width: 70px!important;
  }
}

.report-card, .governance-report-card {
  print-color-adjust: exact;
  width: 300px;
  border: 1px solid;
  border-radius: 3px;
  color: #202020;
  flex: auto;
  margin: 10px;
  .colored-header {
    print-color-adjust: exact;
    border-bottom: 1px solid black;
    padding: 10px;
    text-align: right;
    font-size: 12px;
    font-weight: 800;
  }
  .content {
    padding: 10px;
    .label-row {
      margin-bottom: 20px;
    }
    .value-row {
      text-align: center;
      margin-bottom: 20px;
    }
    .explanation {
      font-size: 12px;
    }
    .label {
      font-size: 17px;
      font-weight: 800;
    }
    .value {
      font-size: 32px;
      font-weight: 800;
    }
    .unit {
      font-size: 12px;
      position: relative;
      top: -14px;
      left: 5px;
    }
  }
}
.governance-report-card {
  min-width: 70%;
  max-width: 600px;
  .colored-header {
    print-color-adjust: exact;
    display: flex;
    justify-content: space-between;
    div{
      width: 30%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    div:first-child {
      justify-content: flex-start;
      width: 70%;
    }
  }
  .out-of {
    margin: 0 15px;
  }
}

.reportChart {
  .recharts-label {
    fill: white!important;
    tspan {
      fill: white!important;
      stroke: white;
    }
  }
}

.dynamicDescription {
  br {
    margin:2.5em 0;/* FF for instance */
    line-height:5em;/* chrome for instance */
  }
  text-align: justify;
}

@media print {
  .holdTogetherOnPrint {
    break-inside: avoid;
  }
  .modal-backdrop.show {
    opacity: 1;
    background: white;
  }
}

.nav-link {
  cursor: pointer;
}

.bg-purple {
  background-color: $primary!important;
}
.text-white {
  color: white!important;
}
.header {
  background-color: $primary!important;
  height: 100px;
  position: sticky !important;
  top: 0;
  z-index: 9;
}
.logo--large {
    // padding: 1rem 0;
  }

.uq-footer{
  font-family: "Roboto","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: .875rem;
  padding: 15px 30px;
  background-color: $primary;
  color: white;
  display: flex;
  justify-content: space-between;
  align-content: center;
  &__footer-item{
    margin: 0 10px;
  }
}

.navbar {
      padding: 25px;
}

.navbar-brand {
  margin-right: 25px;
}

.btn.disabled {
  cursor:not-allowed;
  pointer-events: visibleStroke;
}