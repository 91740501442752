@import "variables";

@import url("https://static.uq.net.au/v15/fonts/Roboto/roboto.css");
@import url("https://static.uq.net.au/v15/fonts/Merriweather/merriweather.css");
@import url("https://static.uq.net.au/v15/fonts/Montserrat/montserrat.css");

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-family-monospace: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

body {
  margin: 0;
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}


.table > thead > tr{
  border-top: hidden;
  color: #72777a;
}

.h-100vh {
  min-height: 100vh;
}


.overlay {
  background-color: rgba(239, 239, 239, 0.5);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0px;
  left: 0px;

}
