$primary: #51247a !default;

// scss-docs-start color-variables
$blue:    #4085c6 !default;
$indigo:  #6610f2 !default;
$purple:  #962a8b !default;
$pink:    #d63384 !default;
$red:     #e62645 !default;
$orange:  #eb602e !default;
$yellow:  #fbb800 !default;
$green:   #2ea836 !default;
$teal:    #20c997 !default;
$cyan:    #00a2c7 !default;
$gray:    #999490 !default;

$gray-200: lighten($primary, 70%);
$gray-300: lighten($primary, 60%);
$gray-400: lighten($primary, 50%);
$gray-500: lighten($primary, 40%);
//$gray-600: lighten($primary, 30%);
$gray-700: lighten($primary, 20%);
$gray-800: lighten($primary, 10%);
$gray-900: $primary;

// scss-docs-start theme-color-variables
$primary:       #51247a !default;
$secondary:     $gray !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         #fff !default;
$dark:          $gray-900 !default;



$headings-color: $primary;

// Tables
$table-dark-bg: $primary;

$jumbotron-padding: 2rem;
$jumbotron-color: white;
$jumbotron-bg: white;


$border-width:                1px !default;
$border-color:                $gray-300 !default;

$border-radius:               .25rem !default;
$border-radius-lg:            .3rem !default;
$border-radius-sm:            .2rem !default;

$rounded-pill:                50rem !default;
